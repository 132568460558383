import { createRouter, createWebHistory } from 'vue-router'
const routes = [
  {
    path: '/',
    name: 'receipt',
    component: () => import(/* webpackChunkName: "receipt" */ '../views/Receipt.vue')
  },
  {
    path: '/summar',
    name: 'summar',
    component: () => import(/* webpackChunkName: "summar" */ '../views/Summar.vue')
  },
  {
    path: '/detail-summar',
    name: 'detailSummar',
    component: () => import(/* webpackChunkName: "detailSummar" */ '../views/DetailSummar.vue')
  },
  {
    path: '/detail-receipt',
    name: 'detailReceipt',
    component: () => import(/* webpackChunkName: "detailSummar" */ '../views/DetailReceipt.vue')
  },
  {
    path: '/missing-tickets',
    name: 'missingTickets',
    component: () => import(/* webpackChunkName: "missingTickets" */ '../views/MissingTickets.vue')
  },
  {
    path: '/missing-sites',
    name: 'missingSites',
    component: () => import(/* webpackChunkName: "missingSites" */ '../views/MissingSites.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
